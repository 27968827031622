import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";
import { dynamicBundleReplaceListingsPath } from "../routes";

export default class extends Controller {
  static targets = ["mainImage", "form"];

  static values = {
    partIds: String,
    index: Number,
    bundleId: String
  };

  connect() {
    enter(this.element);

    document.getElementById("fake-zendesk-buttons").classList.add("hidden");
    document.getElementById("launcher").classList.add("hidden");
  }

  handleOptionChange(event) {
    this.updateMainImage(event);
    this.updateFormAction(event);
  }

  updateMainImage(event) {
    const { listingImage } = event.currentTarget.dataset;
    this.mainImageTarget.src = listingImage;
  }

  updateFormAction(event) {
    const partIdsArray = this.partIdsValue.split("/");
    const newListingId = event.currentTarget.value;
    partIdsArray[this.indexValue] = newListingId;

    this.formTarget.action = dynamicBundleReplaceListingsPath({
      dynamic_bundle_id: this.bundleIdValue,
      bundleable_ids: partIdsArray.join("/")
    });
  }

  closeModal() {
    leave(this.element);

    document.getElementById("fake-zendesk-buttons").classList.remove("hidden");
    document.getElementById("launcher").classList.remove("hidden");
  }
}
